.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
}

.ck.ck-editor .ck-content{
    max-height: 500px !important;
    min-height: 200px;
    overflow: scroll;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}
.ck.ck-editor .ck-toolbar{
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;   
    /* background: #FFFDFA !important; */
    /* border-color: #F77C32 !important; */
}

.ck-focused { 
    border-color: #F77C32 !important;
    background: #FFFDFA !important;
}
.ck-body-wrapper{
    display: none !important;
}