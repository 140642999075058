@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    input[type="checkbox"].form-checkbox:checked::before {
        padding-top: 2px;
        content: "\2714";
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        line-height: 0.75;
        color: #f77c32;
    }

        input[type="checkbox"].checkbox:checked::before {
        content: "\2714";
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        line-height: 0.75;
        color: #f77c32;
    }

    input[type="radio"] {
        @apply bg-appgray ring-0 border-none;
    }

    input[type="radio"]:checked {
        @apply bg-default ring-0 border-none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
   
    input[type="checkbox"] {
        @apply border-2 border-moregray rounded mr-2;
    }

    input[type="file"] {
        padding: 2px 0;
        &::-webkit-file-upload-button {
            display: none;
        }
        &::file-selector-button {
            display: none;
        }
    }

    input[type="checkbox"]:checked {
        @apply border-2 border-default rounded bg-transparent text-default;
    }

    input[type="checkbox"]:focus {
        box-shadow: none;
    }

    thead {
        @apply border-b-2 border-black;
    }

    th {
        @apply text-left px-4 font-semibold;
    }

    .td-class {
        @apply px-4 py-3 text-gray-900 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg;
    }

    .active {
        @apply bg-default-opacity block rounded-xl text-default;
    }

    .item-tab {
        @apply inline-block p-4 border-b-4 border-transparent -mb-1 text-moregray;
    }

    .item-tab-active {
        @apply inline-block p-4 border-b-4 border-default -mb-1 text-default;
    }

    .active svg {
        @apply fill-default !important;
    }

    svg {
        @apply fill-appblack;
    }

    /* tr {
        @apply text-left;
    } */

    td {
        @apply px-5 py-4 font-medium;
    }

    tbody {
        @apply divide-y;
    }

    .responsive-table {
        @apply min-w-full whitespace-nowrap rounded-lg divide-y overflow-hidden;
    }

    .font-league-spartan {
        font-family: 'League Spartan'
    }
    .btn {
        @apply rounded-full bg-default py-1 text-white !font-normal px-4;
    }

    .btn-cancel {
        @apply rounded-full bg-appgray py-1 !font-normal text-moregray px-4;
    }

    .btn-outline {
        @apply rounded-full border-2 border-default bg-transparent !font-normal text-default py-1 px-4 my-2;
    }

    .form-control {
        @apply rounded-full border border-appgray h-10 px-3 w-full mb-2 focus:outline-none focus:bg-[#FFFDFA] focus:ring-1 focus:ring-default focus-within:outline-none focus-within:ring-1 focus-within:ring-default focus-within:bg-[#FFFDFA] !important;
    }

    .valid-textarea {
        @apply ring-1 ring-default bg-[#FFFDFA] !important;
    }

     .empty-form{
        @apply ring-1 ring-appgray bg-background !important;
    }

    .empty-form input{
        @apply !bg-transparent !important;
    }
    
    .success-form {
        @apply bg-badge-success focus-within:bg-badge-success focus-within:ring-1 focus-within:ring-app-green ring-1 ring-app-green !important;
    }

    .success-form input {
        @apply bg-transparent !important;
    }

    .success-form #password{
        @apply !bg-transparent;
    }

    .error-form {
        @apply bg-badge-error focus-within:bg-badge-error focus-within:ring-1 focus-within:ring-app-red ring-1 ring-app-red !important;
    }

    .error-form input {
        @apply bg-transparent !important;
    }

    .error-form #password{
        @apply !bg-transparent;
    }
    
    .valid-form {
        @apply ring-1 ring-default bg-[#FFFDFA] !important;
    }

    .invalid-form {
        @apply ring-1 ring-badge-error bg-badge-error !important;
    }

    .img-profile {
        @apply rounded-full;
    }

    .card {
        @apply py-3;
    }

    .card-body {
        @apply py-3;
    }

    .card-footer {
        @apply px-5 my-3;
    }

    .app-shadow {
        @apply shadow-[rgba(0,_0,_0,_0.05)_0px_2px_15px_0px] rounded-xl bg-white;
    }

    .app-shadow2 {
        @apply shadow-[rgba(0,_0,_0,_0.05)_0px_2px_15px_0px] ;
    }

    .card-header {
        @apply px-5 font-bold;
    }

    .dropdown-close {
        @apply rounded-full app-shadow relative z-50 !min-w-[245px];
    }

    .dropdown-close > ul {
        @apply hidden;
    }

    .dropdown-open {
        @apply rounded-3xl absolute translate-y-[-4.3%] right-[8px] !min-w-[245px] app-shadow z-50;
    }

    .dropdown-open > ul {
        @apply block;
    }

    .dropdown-open > ul > li {
        @apply mb-2;
    }

    .dropdown-open img.cursor-pointer {
        @apply rotate-180;
    }

    .banner {
        @apply w-full;
    }
    input[type="date"]{
        position: relative;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

      input[type="datetime-local"]{
        position: relative;
    }
    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

}